export const tableFields = [
  {
    key: 'createdAt', label: 'Date & Time', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'operatorName', label: 'Operator', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'tokenName', label: 'Token Name', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'affectedArea', label: 'Affected Area', sortable: false, tdClass: 'align-middle',
  },
  {
    key: 'actionType', label: 'Action Type', sortable: false, tdClass: 'align-middle',
  },
  {
    key: 'actions',
    label: ' ',
    tdClass: 'text-nowrap align-middle text-center',
  },
];

export const affectedAreaMap = {
  '/investors': 'Investors',
  '/operators': 'Administration Panel',
  '/blockchain-transactions': 'Blockchain Signature',
  '/investors-bulk': 'Investor Bulk Action',
};

export const actionTypeMap = {
  POST: 'Create',
  PATCH: 'Update',
  PUT: 'Update',
  DELETE: 'Remove',
  GET: 'Get',
};
