<template>
  <div class="audit-log-table-container">
    <cp-table
      ref="cpTable"
      default-sort-by="createdAt"
      default-sort-direction="desc"
      :filters="filters"
      get-data-action="auditLogIssuer/getAuditLogsListByIssuerId"
      :url-params="urlParams"
      :fields="tableFields"
      :search-value="searchQueryParam"
      table-title="Issuer Audit Log"
      @tableDataUpdated="onTableDataUpdated"
    >
      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>
          {{ rowData.item.createdAt | standardDateFilter }}
        </span>
      </template>
      <template
        slot="operatorName"
        slot-scope="{ rowData }"
      >
        <span>
          {{ getOperatorName(rowData.item) }}
        </span>
      </template>
      <template
        slot="tokenName"
        slot-scope="{ rowData }"
      >
        <span>
          {{ getTokenName(rowData.item) }}
          {{ getTokenBlockchainNetwork(rowData.item) }}
        </span>
      </template>
      <template
        slot="affectedArea"
        slot-scope="{ rowData }"
      >
        <span>
          {{ getAffectedArea(rowData.item) }}
        </span>
      </template>
      <template
        slot="actionType"
        slot-scope="{ rowData }"
      >
        <span>
          {{ getActionType(rowData.item) }}
        </span>
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <div class="d-flex justify-content-start">
          <b-btn
            v-b-tooltip.hover="true"
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.view')"
            @click="viewAuditLog(rowData.item)"
          >
            <i class="ion ion-md-eye" />
            {{ $t('common.button.view') }}
          </b-btn>
        </div>
      </template>
    </cp-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CpTable from '~/components/shared/cp-table';
import CpTimeConverter from '~/mixins/time-converter';
import { tableFields, affectedAreaMap, actionTypeMap } from './options';
import { BLOCKCHAIN_NETWORK_MAPPER } from '../../investors/issue-details/components/options';

export default {
  name: 'CpAuditLogIssuerTable',
  components: {
    CpTable,
  },
  mixins: [
    CpTimeConverter,
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableFields,
      totalTableData: [],
      urlParams: {
        issuerId: this.$route.params.idIssuer,
      },
      searchQueryParam: this.$route.query.search || undefined,
      isEmptyList: false,
    };
  },
  computed: {
    ...mapGetters('global', ['issuers']),
    ...mapGetters('auditLogIssuer', ['getTokensMap']),
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    ...mapActions('auditLogIssuer', ['getAuditLogsListByIssuerId']),
    onTableDataUpdated({ items, totalItems }) {
      this.totalTableData = items;
      this.isEmptyList = totalItems === 0;
    },
    getOperatorName({ operator }) {
      if (!operator) {
        return 'N/A';
      }
      return operator.name;
    },
    getTokenName({ token }) {
      return this.getTokensMap[token.id]?.name ? this.getTokensMap[token.id].name : 'N/A';
    },
    getTokenBlockchainNetwork({ token }) {
      return this.getTokensMap[token.id]?.blockchainNetwork ? `- ${BLOCKCHAIN_NETWORK_MAPPER[this.getTokensMap[token.id].blockchainNetwork]}` : '';
    },
    getAffectedArea({ url }) {
      const affectedAreaKey = Object.keys(affectedAreaMap).filter(key => (url || '').includes(key));
      return affectedAreaMap[affectedAreaKey] || 'N/A';
    },
    getActionType({ method }) {
      const actionType = actionTypeMap[method];
      return actionType || 'N/A';
    },
    viewAuditLog(item) {
      this.$emit('onView', item);
    },
  },
};
</script>

<style lang="scss">
</style>
