<template>
  <cp-filter
    ref="cpFilter"
    name="auditLog"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md filterBlock">
        <cp-select
          name="operator"
          :label="$t('auditLog.label.filter.operator')"
          :options="operatorOptions"
          :value="'null'"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-select
          name="token"
          :label="$t('auditLog.label.filter.token')"
          :options="tokenOptions"
          :value="'null'"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-date-range
          v-validate="'date_range'"
          name="from-created-at"
          :label="$t('auditLog.label.filter.dateRange')"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpDateRange from '~/components/common/standalone-components/inputs/cp-date-range';
import CpFilter from '~/components/common/cp-filter';
import filterParseForApi from '~/utilities/investorFilterParseForApi';

export default {
  name: 'AuditLogIssuerFilter',
  components: {
    CpSelect,
    CpDateRange,
    CpFilter,
  },
  data() {
    return {
      operatorsList: [],
      tokensList: [],
      queryParams: {
        issuerId: this.$route.params.idIssuer,
      },
    };
  },
  computed: {
    ...mapGetters('auditLogIssuer', ['getOperatorOptions', 'getTokenOptions']),
    operatorOptions() {
      return [{ value: null, text: 'All' }, ...(this.getOperatorOptions || [])];
    },
    tokenOptions() {
      return [{ value: null, text: 'All' }, ...(this.getTokenOptions || [])];
    },
  },
  async created() {
    const { issuerId } = this.queryParams;
    await this.getOperatorListByIssuerId({ issuerId });
    await this.getTokenListByIssuerId({ issuerId });
  },
  methods: {
    ...mapActions('auditLogIssuer', ['getOperatorListByIssuerId', 'getTokenListByIssuerId']),
    updateFilter() {
      this.$emit('updateFilter', this.getActiveFilters());
    },
    getActiveFilters() {
      return filterParseForApi(this.$refs.cpFilter.getDefaultValue);
    },
  },
};
</script>

<style>
  .filterBlock {
    margin: 0 2%;
    min-width: 25%;
  }
</style>
